import React, { FC } from 'react';
import { useSearchParams } from 'react-router-dom';
import cn from 'classnames';
import { useGetMe } from 'api';
import { JanusJS } from 'janus-gateway';
import { Socket } from 'socket.io-client';
import { OtherContent } from 'pages/meeting/constants';
import { useKeyboardEvents } from 'pages/meeting/hooks';
import { QUERY } from 'constants/path';
import {
  Exit,
  Microphone,
  Flashlight,
  Laser,
  Fix,
  VideoFurther,
  Record,
  Arrow,
  Snapshot,
  /* VideoResolution, */
  Options,
  Chat,
  People,
  Fullscreen,
  Video,
  SelectBitrate,
  VisualEffects,
  Translation,
} from './controls';
import style from './controls-panel.module.scss';

type ControlsPanelProps = {
  socket: Socket | null;
  stream: MediaStream | null;
  pluginHandle: JanusJS.PluginHandle | null;
  typeOtherContent: OtherContent | null;
  isFullscreen: boolean;
  isHasAccessMicrophone: boolean;
  isHasAccessVideo: boolean;
  isActiveMicrophone: boolean;
  isActiveVideo: boolean;
  isShowVisualEffects: boolean;
  isTranslation: boolean;
  setIsTranslation: React.Dispatch<React.SetStateAction<boolean>>;
  setIsFullScreen: React.Dispatch<React.SetStateAction<boolean>>;
  setIsShowVisualEffects: React.Dispatch<React.SetStateAction<boolean>>;
  setIsTypeOtherContent: React.Dispatch<React.SetStateAction<OtherContent | null>>;
  setIsActiveMeeting: React.Dispatch<React.SetStateAction<boolean>>;
  setPause: React.Dispatch<React.SetStateAction<boolean>>;
};

export const ControlsPanel: FC<ControlsPanelProps> = ({
  socket,
  stream,
  pluginHandle,
  typeOtherContent,
  isFullscreen,
  isHasAccessMicrophone,
  isHasAccessVideo,
  isActiveMicrophone,
  isActiveVideo,
  isShowVisualEffects,
  isTranslation,
  setIsTranslation,
  setIsFullScreen,
  setIsShowVisualEffects,
  setIsTypeOtherContent,
  setIsActiveMeeting,
  setPause,
}) => {
  useKeyboardEvents(socket);
  const [query] = useSearchParams();
  const headsetId = query.get(QUERY.headsetId);
  const { data: user } = useGetMe();
  const isMyHeadset = headsetId === user?.headset?.id;

  return (
    <div className={cn(style.block, { [style.block__fullscreen]: isFullscreen })}>
      <div className={style.container}>
        <Exit setIsActiveMeeting={setIsActiveMeeting} setPause={setPause} />
        {pluginHandle && (
          <>
            <Microphone isActive={isActiveMicrophone} isHasAccess={isHasAccessMicrophone} pluginHandle={pluginHandle} />
            <Video isActive={isActiveVideo} isHasAccess={isHasAccessVideo} pluginHandle={pluginHandle} />
            <Translation isTranslation={isTranslation} setIsTranslation={setIsTranslation} />
            <SelectBitrate pluginHandle={pluginHandle} />
          </>
        )}
        {socket && (
          <>
            {!isMyHeadset && (
              <>
                <Flashlight socket={socket} />
                <Laser socket={socket} />
                <Fix socket={socket} />
                <VideoFurther socket={socket} />
              </>
            )}
            <Record socket={socket} />
            {!isMyHeadset && (
              <div className={style.arrowsBlock}>
                <Arrow socket={socket} id='left' />
                <Arrow socket={socket} id='up' />
                <Arrow socket={socket} id='right' />
                <Arrow socket={socket} id='down' />
              </div>
            )}
            <Snapshot socket={socket} stream={stream} />
            {/* <VideoResolution socket={socket} /> */}
            <VisualEffects isShowVisualEffects={isShowVisualEffects} setIsShowVisualEffects={setIsShowVisualEffects} />
            <Fullscreen isFullscreen={isFullscreen} setIsFullScreen={setIsFullScreen} />
            <Chat typeOtherContent={typeOtherContent} setIsTypeOtherContent={setIsTypeOtherContent} />
            <People typeOtherContent={typeOtherContent} setIsTypeOtherContent={setIsTypeOtherContent} />
            {!isMyHeadset && <Options socket={socket} />}
          </>
        )}
      </div>
    </div>
  );
};
