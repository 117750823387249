export const MEETING_SLICE = {
  headerTitle: 'Kulaklık konferansı:',
  you: 'Sen',
  off: 'Kapat',
  on: 'Aç',
  wrap: 'Küçült',
  unwrap: 'Büyüt',
  show: 'Göster',
  hidden: 'Gizle',
  toggleMic: '{{status}} mikrofon',
  toggleVideo: '{{status}} video',
  micNotFound: 'Mikrofon mevcut değil',
  videoNotFound: 'Kamera mevcut değil',
  audioinput: 'Ses kayıt cihazı',
  videoinput: 'Video kayıt cihazı',
  roles: {
    engineer: {
      title: 'Mühendisler odasında: {{count}}',
      buttonTitle: 'Mühendis olarak gir',
      description:
        'Sadece bir mühendis girebilir, mühendislere bonuslar verilir, mühendisin hakları ve olanaklarına dair açıklama',
    },
    expert: {
      title: 'Uzmanlar odasında: {{count}}',
      buttonTitle: 'Uzman olarak gir',
      description:
        'Sadece bir uzman girebilir, uzmanlara bonuslar verilir, uzmanın hakları ve olanaklarına dair açıklama',
    },
    observer: {
      title: 'Gözlemciler odasında: {{count}}',
      buttonTitle: 'Gözlemci olarak gir',
      description:
        'En fazla üç gözlemci girebilir, gözlemcilere bonus verilmez, gözlemcilerin hakları ve olanaklarına dair açıklama',
    },
    security: {
      title: 'Güvenlik odasında: {{count}}',
      buttonTitle: 'Güvenlik olarak gir',
      description:
        'Sadece bir güvenlik personeli girebilir, güvenlik personeline bonus verilmez, güvenlik personelinin hakları ve olanaklarına dair açıklama',
    },
  },
  failedBanner:
    'Kamera ve mikrofona erişim yok,\nonları bağlayın veya kullanımları için izin verin,\nve ardından sayfayı yenileyin',
  continueConference: 'Konferansı sürdür',
  controls: {
    arrow: {
      up: 'Yukarı',
      down: 'Aşağı',
      left: 'Sol',
      right: 'Sağ',
    },
    chat: 'Sohbet',
    exit: 'Çıkış',
    translation: '{{status}} çeviri',
    fix: '{{status}} görüş alanını sabitle',
    light: '{{status}} fener',
    video: '{{status}} video',
    laser: '{{status}} lazer',
    mic: '{{status}} mikrofon',
    record: '{{status}} odayı kaydet',
    videoFurther: '{{status}} videoyu daha fazla sınırla',
    visualEffects: '{{status}} görsel efektler',
    people: 'İnsanlar listesi',
    snapshot: 'Ekran görüntüsü al',
    optionstTitle: 'Ayarlar',
    options: {
      mouse_sensitivity: 'Fare hassasiyeti:',
      keyboard_sensitivity: 'Klavye hassasiyeti:',
      depth_view: 'Videoyu daha fazla kısıtla:',
    },
  },
};
