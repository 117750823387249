import React, { FC, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import cn from 'classnames';
import { useGetHeadset, useGetMe } from 'api';
import { Socket } from 'socket.io-client';
import { QUERY } from 'constants/path';
import style from './controls.module.scss';

type SnapshotProps = {
  socket: Socket;
  //TODO: принимать стрим с гарнитуры
  stream: MediaStream | null;
};

export const Snapshot: FC<SnapshotProps> = props => {
  const [query] = useSearchParams();
  const headsetId = query.get(QUERY.headsetId);
  const { data: headsetData } = useGetHeadset({ id: headsetId });
  const { data: me } = useGetMe();
  const isMyHeadset = headsetData?.as_user?.id === me?.id;

  return isMyHeadset ? <HeadsetSnapshot {...props} /> : <UserSnapshot {...props} />;
};

const UserSnapshot: FC<SnapshotProps> = ({ socket, stream }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'meeting' });

  const handleClick = () => {
    socket.emit('snapshot');
  };

  return (
    <>
      <button className={cn(style.button, style.button__snapshot)} title={t('controls.snapshot')} onClick={handleClick}>
        Snapshot
      </button>
    </>
  );
};

const HeadsetSnapshot: FC<SnapshotProps> = ({ socket, stream }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'meeting' });
  const videoRef = useRef<HTMLVideoElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    const { current: video } = videoRef;

    if (video) {
      video.srcObject = stream;
    }
  }, [stream]);

  const handleTakeScreenshot = () => {
    if (!stream) return;

    const canvas = canvasRef.current;
    const video = videoRef.current;
    if (!canvas || !video) return;

    const context = canvas.getContext('2d');
    if (!context) return;

    const { width: streamWidth, height: streamHeight } = stream.getVideoTracks()[0].getSettings();

    if (streamWidth && streamHeight) {
      context.drawImage(video, 0, 0, streamWidth, streamHeight);
      const base64 = canvas.toDataURL();
      const cleanBase64 = base64.replace(/^data:image\/(png|jpg);base64,/, '');
      socket.emit('send_snapshot', cleanBase64);
    }
  };

  socket.on('snapshot', handleTakeScreenshot);

  const handleClick = () => {
    handleTakeScreenshot();
  };

  return (
    <>
      <button className={cn(style.button, style.button__snapshot)} title={t('controls.snapshot')} onClick={handleClick}>
        Snapshot
      </button>
      <video ref={videoRef} style={{ display: 'none' }} autoPlay playsInline />
      <canvas
        ref={canvasRef}
        style={{
          display: 'none',
        }}
        width='1000px'
        height='500'
      ></canvas>
    </>
  );
};
