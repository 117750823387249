import { useState, useEffect, useCallback } from 'react';
import Janus, { type JanusJS } from 'janus-gateway';

type Stream = {
  mid: string;
  type: 'video' | 'audio';
};
type Publisher = {
  id: string;
  display: string;
  streams: Stream[];
};
type RemoteStream = Omit<Publisher, 'streams'> & {
  pluginHandle: JanusJS.PluginHandle;
  stream: MediaStream;
};
export type RemoteStreams = Record<string, RemoteStream>;

const opaqueId = 'videoroomtest-' + Janus.randomString(12);
Janus.init({});

type UseJanusParams = {
  userName: string;
  roomId: number;
  videoDeviceId: string;
  audioDeviceId: string;
  isHasAccessMicrophone: boolean;
  isHasAccessVideo: boolean;
};

export const useJanus = ({
  userName,
  roomId,
  videoDeviceId,
  audioDeviceId,
  isHasAccessMicrophone,
  isHasAccessVideo,
}: UseJanusParams) => {
  const [stream, setStream] = useState<MediaStream | null>(null);
  const [pluginHandle, setPluginHandle] = useState<JanusJS.PluginHandle | null>(null);
  const [remoteStreams, setRemoteStreams] = useState<RemoteStreams>({});

  const newRemoteFeed = useCallback(
    (janus: Janus, privateId: string, publisher: Publisher) => {
      let pluginHandle: JanusJS.PluginHandle;
      janus.attach({
        plugin: 'janus.plugin.videoroom',
        opaqueId: opaqueId,
        success: _pluginHandle => {
          pluginHandle = _pluginHandle;
          pluginHandle.send({
            message: {
              request: 'join',
              room: roomId,
              ptype: 'subscriber',
              streams: publisher.streams.map(({ mid }) => ({ feed: publisher.id, mid })),
              private_id: privateId,
            },
            error: e => {
              console.error(e);
            },
          });
        },
        onmessage: (msg, jsep) => {
          const event = msg.videoroom;
          if (event === 'attached') {
            // ...
          } else if (event === 'event') {
            // ...
          }

          if (jsep) {
            const stereo = jsep.sdp?.indexOf('stereo=1') !== -1;
            pluginHandle.createAnswer({
              jsep: jsep,
              // We only specify data channels here, as this way in
              // case they were offered we'll enable them. Since we
              // don't mention audio or video tracks, we autoaccept them
              // as recvonly (since we won't capture anything ourselves)
              // @ts-ignore
              tracks: [{ type: 'data' }],
              customizeSdp: (jsep: any) => {
                if (stereo && jsep.sdp.indexOf('stereo=1') === -1) {
                  jsep.sdp = jsep.sdp.replace('useinbandfec=1', 'useinbandfec=1;stereo=1');
                }
              },
              success: jsep => {
                pluginHandle.send({ message: { request: 'start', room: roomId }, jsep: jsep });
              },
              error: e => {
                console.error(e);
              },
            });
          }
        },
        onremotetrack: (track, mid, isOn, metadata) => {
          setRemoteStreams(remoteStreams => {
            const stream =
              remoteStreams[publisher.id]?.stream ||
              new MediaStream([
                /* track */
              ]);
            if (isOn) {
              stream.addTrack(track);
            } else {
              stream.removeTrack(track);
            }

            return {
              ...remoteStreams,
              [publisher.id]: {
                id: publisher.id,
                display: publisher.display,
                pluginHandle,
                stream,
              },
            };
          });
        },

        error: e => {
          console.error(e);
        },
      });
    },
    [roomId],
  );

  useEffect(() => {
    if (!roomId || !userName) return;

    let pluginHandle: JanusJS.PluginHandle;
    //let myId: string;
    let myPrivateId: string;

    const janus = new Janus({
      server: process.env.REACT_APP_API_JANUS_URL!,
      success: () => {
        janus.attach({
          plugin: 'janus.plugin.videoroom',
          opaqueId: opaqueId,
          success: _pluginHandle => {
            pluginHandle = _pluginHandle;
            setPluginHandle(_pluginHandle);
            pluginHandle.send({
              message: {
                request: 'join',
                room: roomId,
                ptype: 'publisher',
                display: userName,
              },
            });
          },
          onmessage: (msg, jsep) => {
            const event = msg.videoroom;
            if (event === 'joined') {
              //myId = msg.id;
              myPrivateId = msg.private_id;

              const tracks: JanusJS.TrackOption[] = [];

              if (isHasAccessMicrophone) {
                //@ts-ignore
                tracks.push({ type: 'audio', recv: false, capture: { deviceId: audioDeviceId } });
              }
              if (isHasAccessVideo) {
                tracks.push({
                  type: 'video',
                  recv: false,
                  simulcast: false,
                  //@ts-ignore
                  capture: { deviceId: videoDeviceId /* width: { ideal: 3840 }, height: { ideal: 2160 }  */ },
                });
              }

              // Publish own feed
              pluginHandle.createOffer({
                tracks,
                success: jsep => {
                  pluginHandle.send({ message: { request: 'configure', audio: true, video: true }, jsep: jsep });
                },
              });
            } else if (event === 'event') {
              if (msg.unpublished) {
                const publisherId = msg.unpublished;
                setRemoteStreams(remoteStreams => {
                  const newRemoteStreams = { ...remoteStreams };
                  remoteStreams[publisherId]?.pluginHandle?.detach();
                  delete newRemoteStreams[publisherId];
                  return newRemoteStreams;
                });
              } else if (msg.leaving) {
                const leavingId = msg.leaving;
                setTimeout(() => {
                  setRemoteStreams(remoteStreams => {
                    const newRemoteStreams = { ...remoteStreams };
                    remoteStreams[leavingId]?.pluginHandle?.detach();
                    delete newRemoteStreams[leavingId];
                    return newRemoteStreams;
                  });
                });
              }
            }

            // Handle existing remote sessions
            const publishers = msg.publishers;
            if (publishers) {
              publishers.forEach((item: any) => newRemoteFeed(janus, myPrivateId, item));
            }

            if (jsep) {
              pluginHandle.handleRemoteJsep({ jsep: jsep });
            }
          },
          onlocaltrack: (track, isEnabled) => {
            if (isEnabled) {
              if (track.kind === 'video') {
                setStream(new MediaStream([track]));
              }
            } else {
              setStream(null);
            }
          },
        });
        //
      },
    });

    return () => {
      janus.destroy({ cleanupHandles: true });
    };
  }, [userName, roomId, newRemoteFeed]);

  return { stream, remoteStreams, pluginHandle, setRemoteStreams };
};
